<template lang="pug">
v-dialog(
  v-model="dialogSearch",
  :scrollable="true",
  :max-width="1440",
  persistent
)
  v-card
    v-container.px-0(fluid)
      v-card-title.flex-column.justify-center.py-0.py-md-3
        v-row.align-center.w-100
          v-col.pr-1(cols="6", sm="3", md="3", lg="2")
            select(v-model="mainSelect")
              option(value="ALL") 全部
              option(value="M") 月
              option(value="W") 週
          v-col.pl-0(
            v-if="mainSelect != 'ALL'",
            cols="6",
            sm="3",
            md="3",
            lg="2"
          )
            select(v-model="subSelect")
              option(v-for="(item, index) in subSelectList", :value="item") {{ item.name }}
          v-spacer
          v-col(cols="12", sm="6", md="3", lg="4")
            input.search(placeholder="搜尋玩家", v-model="nameText")
    v-card-text.text-center.mt-3.px-0
      v-container.ranking_container.container--fluid.px-7.pt-0.pb-10.mt-12
        .d-flex.justify-center
          h2.text-center.text_f_primary 總積分
        v-row
          v-col
            h3 玩家排行
        v-row.table_row.mt-0
          v-col.table.f_table.table_col
            table
              thead
                tr.bottom_head
                  th.text-left 名字
                  th(
                    style="cursor: pointer",
                    @click="sortClick('ast', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast' && sortValue.order == 'DESC' }"
                  ) 
                    span 助攻
                    font-awesome-icon.ml-2(:icon="getTableSort('ast')") 
                  th(
                    style="cursor: pointer",
                    @click="sortClick('reb', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'reb' && sortValue.order == 'DESC' }"
                  ) 
                    span 籃板
                    font-awesome-icon.ml-2(:icon="getTableSort('reb')") 
                  th(
                    style="cursor: pointer",
                    @click="sortClick('stl', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'stl' && sortValue.order == 'DESC' }"
                  ) 
                    span 抄截
                    font-awesome-icon.ml-2(:icon="getTableSort('stl')") 
                  th(
                    style="cursor: pointer",
                    @click="sortClick('blk', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'blk' && sortValue.order == 'DESC' }"
                  ) 
                    span 火鍋
                    font-awesome-icon.ml-2(:icon="getTableSort('blk')")
                  th(
                    style="cursor: pointer",
                    @click="sortClick('pts', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pts' && sortValue.order == 'DESC' }"
                  ) 
                    span 得分
                    font-awesome-icon.ml-2(:icon="getTableSort('pts')")
                  th(
                    style="cursor: pointer",
                    @click="sortClick('score', 'INFO')",
                    :class="{ sort_up: sortValue.type == 'score' && sortValue.order == 'ASC', sort_down: sortValue.type == 'score' && sortValue.order == 'DESC' }"
                  ) 
                    span 積分
                    font-awesome-icon.ml-2(:icon="getTableSort('score')")
              tbody
                tr(v-if="currentList.length == 0")
                  td.bdl(colspan="8") 請搜尋玩家名字
                tr(
                  v-if="currentList.length > 0",
                  v-for="(data, index) in currentList",
                  :key="index",
                  style="cursor: pointer",
                  :class="getSwitchRankPlayer(index)",
                  @click="setSwitchRankPlayer(index)"
                )
                  td
                    .player_container.d-flex.align-center(
                      @click.stop="showOtherTeamDialog(data)",
                      style="cursor: pointer"
                    )
                      img.avatar(
                        v-if="data.user && data.user.photo && data.user.photo != ''",
                        :src="data.user.photo"
                      )
                      img.avatar(
                        v-if="!data.user || !data.user.photo || data.user.photo == ''",
                        :src="gcAvatar"
                      )
                      span.ml-1 {{ data.user.nickname }}
                  td 
                    span.value {{ data.box.ast }}
                    span.rank(:class="getRankStyle(data.ranking_ast)") {{ getRankText(data.ranking_ast) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_ast, data.ranking_ast)"
                      ) {{ Math.abs(data.last_ranking_ast - data.ranking_ast) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_ast, data.ranking_ast)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.reb }}
                    span.rank(:class="getRankStyle(data.ranking_reb)") {{ getRankText(data.ranking_reb) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_reb, data.ranking_reb)"
                      ) {{ Math.abs(data.last_ranking_reb - data.ranking_reb) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_reb, data.ranking_reb)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.stl }}
                    span.rank(:class="getRankStyle(data.ranking_stl)") {{ getRankText(data.ranking_stl) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_stl, data.ranking_stl)"
                      ) {{ Math.abs(data.last_ranking_stl - data.ranking_stl) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_stl, data.ranking_stl)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.blk }}
                    span.rank(:class="getRankStyle(data.ranking_blk)") {{ getRankText(data.ranking_blk) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_blk, data.ranking_blk)"
                      ) {{ Math.abs(data.last_ranking_blk - data.ranking_blk) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_blk, data.ranking_blk)",
                        className="rank_icon"
                      )
                  td 
                    span.value {{ data.box.pts }}
                    span.rank(:class="getRankStyle(data.ranking_pts)") {{ getRankText(data.ranking_pts) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking_pts, data.ranking_pts)"
                      ) {{ Math.abs(data.last_ranking_pts - data.ranking_pts) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking_pts, data.ranking_pts)",
                        className="rank_icon"
                      )
                  td
                    span.value {{ data.score }}
                    span.rank(:class="getRankStyle(data.ranking)") {{ getRankText(data.ranking) }}
                      span.rank_num(
                        :class="getRankRise(data.last_ranking, data.ranking)"
                      ) {{ Math.abs(data.last_ranking - data.ranking) }}
                      svg-icon(
                        :iconClass="getRankRise(data.last_ranking, data.ranking)",
                        className="rank_icon"
                      )
        v-row
          v-col
            v-pagination(
              v-model="currentPage",
              :length="totalPages",
              prev-icon="mdi-menu-left",
              next-icon="mdi-menu-right",
              circle
            )
    v-card-actions.justify-center
      v-btn(outlined, color="dark-1", text="", @click="closeDialog") 關閉
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import { validStringEmpty } from "@/utils/validate";
import { getSeasonData, getOverviewDataBySearch } from "@/api/fantasy";

export default {
  name: "DialogSearch",
  props: {
    dialogSearch: Boolean,
    icon: String,
  },
  data() {
    return {
      nameText: "",

      seasonData: {},
      overviewData: {},

      mainSelect: "ALL",
      subSelect: {},
      subSelectList: [],

      allList: [],
      currentList: [],
      personalData: {},
      currentPage: 1,
      totalPages: 1,
      perPage: 10,

      sortValue: {
        type: "",
        order: "DESC",
      },

      dialogOtherTeam: false,
      gcAvatar,
      switchRankPlayer: -1,
      switchRankPersonal: false,
    };
  },
  watch: {
    dialogSearch() {
      if (!this.dialogSearch) {
        this.nameText = "";
        this.seasonData = {};
        this.overviewData = {};
        this.mainSelect = "ALL";
        this.subSelect = {};
        this.subSelectList = [];
        this.allList = [];
        this.currentList = [];
        this.personalData = {};
        this.currentPage = 1;
        this.totalPages = 1;
        this.sortValue = {
          type: "",
          order: "DESC",
        };
        this.switchRankPlayer = -1;
        this.switchRankPersonal = false;
      } else {
        this.getSeasonData();
      }
    },
    currentPage() {
      this.getCurrentData();
    },
    mainSelect() {
      this.overviewData = {};
      this.allList = [];
      this.currentList = [];
      this.personalData = {};
      this.currentPage = 1;
      this.totalPages = 1;

      switch (this.mainSelect) {
        case "ALL":
          this.subSelectList = [];
          this.subSelect = {};
          this.getOverviewDataBySearch("ALL", null);
          break;
        case "M":
          this.subSelectList = [];
          for (let index in this.seasonData.valid_months) {
            let temp = this.seasonData.valid_months[index];
            let item = {
              id: temp.id,
              key_first: temp.year,
              key_second: temp.month,
              name:
                temp.month < 10
                  ? temp.year + "0" + temp.month
                  : temp.year + "" + temp.month,
            };
            if (index == 0) {
              this.subSelect = item;
            }
            this.subSelectList.push(item);
          }
          this.getOverviewDataBySearch("MONTH", this.subSelect.id);
          break;
        case "W":
          this.subSelectList = [];
          for (let index in this.seasonData.valid_weeks) {
            let temp = this.seasonData.valid_weeks[index];
            let item = {
              id: temp.id,
              key_first: temp.start_date,
              key_second: temp.end_date,
              name: "~" + temp.end_date,
            };
            if (index == 0) {
              this.subSelect = item;
            }
            this.subSelectList.push(item);
          }
          this.getOverviewDataBySearch("WEEK", this.subSelect.id);
          break;
      }
    },
    subSelect() {
      this.overviewData = {};
      this.allList = [];
      this.currentList = [];
      this.personalData = {};
      this.currentPage = 1;
      this.totalPages = 1;

      switch (this.mainSelect) {
        case "M":
          this.getOverviewDataBySearch("MONTH", this.subSelect.id);
          break;
        case "W":
          this.getOverviewDataBySearch("WEEK", this.subSelect.id);
          break;
      }
    },
    nameText() {
      this.overviewData = {};
      this.allList = [];
      this.currentList = [];
      this.personalData = {};
      this.currentPage = 1;
      this.totalPages = 1;
      if (!validStringEmpty(this.nameText)) {
        switch (this.mainSelect) {
          case "ALL":
            this.getOverviewDataBySearch("ALL", null);
            break;
          case "M":
            this.getOverviewDataBySearch("MONTH", this.subSelect.id);
            break;
          case "W":
            this.getOverviewDataBySearch("WEEK", this.subSelect.id);
            break;
        }
      }
    },
  },
  created() {
    this.getSeasonData();
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
      });
    },
    getOverviewDataBySearch(type, id) {
      if (!validStringEmpty(this.nameText)) {
        let temp;
        if (id == null) {
          temp = {
            season_id: this.seasonData.id,
            key: this.nameText,
            type: type,
          };
        } else {
          temp = {
            season_id: this.seasonData.id,
            key: this.nameText,
            type: type,
            type_id: id,
          };
        }
        getOverviewDataBySearch(temp).then((response) => {
          this.overviewData = response.data;
          this.sortValue.type = "score";
          this.allList = this.overviewData.score_list;
          this.getCurrentData();
        });
      }
    },
    getCurrentData() {
      this.totalPages = Math.ceil(this.allList.length / this.perPage);
      this.currentList = [];
      let number = this.perPage * this.currentPage;
      this.currentList = this.allList.slice(number - this.perPage, number);
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "caret-down"];
      }
    },
    sortClick(type) {
      if (
        this.overviewData.pts_list == undefined ||
        this.sortValue.type == type
      ) {
        return;
      }
      this.sortValue.type = type;
      switch (type) {
        case "pts":
          this.allList = this.overviewData.pts_list;
          break;
        case "blk":
          this.allList = this.overviewData.blk_list;
          break;
        case "stl":
          this.allList = this.overviewData.stl_list;
          break;
        case "reb":
          this.allList = this.overviewData.reb_list;
          break;
        case "ast":
          this.allList = this.overviewData.ast_list;
          break;
        case "score":
          this.allList = this.overviewData.score_list;
          break;
      }
      this.currentPage = 1;
      this.getCurrentData();
    },
    getRankStyle(rank) {
      if (rank == 1) {
        return "gold";
      } else if (rank == 2) {
        return "sliver";
      } else if (rank == 3) {
        return "copper";
      } else {
        return "";
      }
    },
    getRankText(rank) {
      switch (rank) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return rank + "th";
      }
    },
    getRankRise(last, current) {
      if (last == 0) {
        return "rank_flat";
      } else if (last - current < 0) {
        return "rank_down";
      } else if (last - current > 0) {
        return "rank_up";
      } else {
        return "rank_flat";
      }
    },
    getSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        return "switch_rank";
      } else {
        return "";
      }
    },
    setSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        this.switchRankPlayer = -1;
      } else {
        this.switchRankPlayer = index;
      }
    },
    closeDialog() {
      this.$emit("closeSearchDialog");
    },
    showOtherTeamDialog(data) {
      this.$emit("showOtherTeamDialog", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/fantasy/overview";

select {
  font-size: 16px;
}

input.search {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
  border-bottom: 1px solid #8eb0d6;
  font-size: 16px;
  padding: 8px 12px;
}

@media (max-width: 959px) {
  select {
    font-size: 14px;
  }
}
@media (max-width: 599px) {
  input.search {
    font-size: 14px;
    padding: 4px;
  }

  ::v-deep .v-dialog {
    margin: 0;
    max-height: 95%;
  }
}
</style>
