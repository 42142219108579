<template lang="pug">
.overview
  v-container.tab_container.bg-rounded.mt-6(style="padding: 0", fluid)
    v-row.px-2.justify-space-between.align-center.ma-0
      v-col.pr-1(cols="5", sm="3", md="3")
        select(v-model="mainSelect")
          option(value="ALL") 全部
          option(value="M") 月
          option(value="W") 週
      v-col.pl-0(v-if="mainSelect != 'ALL'", cols="5", sm="3", md="3")
        select(v-model="subSelect")
          option(v-for="(item, index) in subSelectList", :value="item") {{ item.name }}
      v-spacer
      v-col(cols="2", sm="2", md="2")
        .btn.search_btn(@click="showSearchDialog")
          span.d-none.d-sm-inline 搜尋玩家
          font-awesome-icon.ml-0.ml-sm-1(:icon="['fas', 'search']")

  v-container.ranking_container.container--fluid.px-0.px-md-7.pt-0.pb-10.mt-12
    .d-flex.justify-center
      h2.text-center.text_f_primary {{ getRankTitle() + '排行榜' }}
    v-row
      v-col
        h3 玩家排行
    v-row.table_row.mt-0
      v-col.table.f_table.table_col
        table
          thead
            tr.bottom_head
              th.text-left 名字
              th(
                style="cursor: pointer",
                @click="sortClick('ast', 'INFO')",
                :class="{ sort_up: sortValue.type == 'ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'ast' && sortValue.order == 'DESC' }"
              ) 
                span 助攻
                font-awesome-icon.ml-2(:icon="getTableSort('ast')") 
              th(
                style="cursor: pointer",
                @click="sortClick('reb', 'INFO')",
                :class="{ sort_up: sortValue.type == 'reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'reb' && sortValue.order == 'DESC' }"
              ) 
                span 籃板
                font-awesome-icon.ml-2(:icon="getTableSort('reb')") 
              th(
                style="cursor: pointer",
                @click="sortClick('stl', 'INFO')",
                :class="{ sort_up: sortValue.type == 'stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'stl' && sortValue.order == 'DESC' }"
              ) 
                span 抄截
                font-awesome-icon.ml-2(:icon="getTableSort('stl')") 
              th(
                style="cursor: pointer",
                @click="sortClick('blk', 'INFO')",
                :class="{ sort_up: sortValue.type == 'blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'blk' && sortValue.order == 'DESC' }"
              ) 
                span 火鍋
                font-awesome-icon.ml-2(:icon="getTableSort('blk')")
              th(
                style="cursor: pointer",
                @click="sortClick('pts', 'INFO')",
                :class="{ sort_up: sortValue.type == 'pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pts' && sortValue.order == 'DESC' }"
              ) 
                span 得分
                font-awesome-icon.ml-2(:icon="getTableSort('pts')")
              th(
                style="cursor: pointer",
                @click="sortClick('score', 'INFO')",
                :class="{ sort_up: sortValue.type == 'score' && sortValue.order == 'ASC', sort_down: sortValue.type == 'score' && sortValue.order == 'DESC' }"
              ) 
                span 積分
                font-awesome-icon.ml-2(:icon="getTableSort('score')")
          tbody
            tr(v-if="currentList.length == 0")
              td.bdl(colspan="8") 尚無資料
            tr(
              v-if="currentList.length > 0",
              v-for="(data, index) in currentList",
              :key="index",
              style="cursor: pointer",
              :class="getSwitchRankPlayer(index)",
              @click="setSwitchRankPlayer(index)"
            )
              td
                .player_container.d-flex.align-center(
                  @click.stop="showOtherTeamDialog(data)",
                  style="cursor: pointer"
                )
                  img.avatar(
                    v-if="data.user && data.user.photo && data.user.photo != ''",
                    :src="data.user.photo"
                  )
                  img.avatar(
                    v-if="!data.user || !data.user.photo || data.user.photo == ''",
                    :src="gcAvatar"
                  )
                  span.ml-1 {{ data.user.nickname }}
              td 
                span.value {{ data.box.ast }}
                span.rank(:class="getRankStyle(data.ranking_ast)") {{ getRankText(data.ranking_ast) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking_ast, data.ranking_ast)"
                  ) {{ Math.abs(data.last_ranking_ast - data.ranking_ast) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking_ast, data.ranking_ast)",
                    className="rank_icon"
                  )
              td 
                span.value {{ data.box.reb }}
                span.rank(:class="getRankStyle(data.ranking_reb)") {{ getRankText(data.ranking_reb) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking_reb, data.ranking_reb)"
                  ) {{ Math.abs(data.last_ranking_reb - data.ranking_reb) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking_reb, data.ranking_reb)",
                    className="rank_icon"
                  )
              td 
                span.value {{ data.box.stl }}
                span.rank(:class="getRankStyle(data.ranking_stl)") {{ getRankText(data.ranking_stl) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking_stl, data.ranking_stl)"
                  ) {{ Math.abs(data.last_ranking_stl - data.ranking_stl) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking_stl, data.ranking_stl)",
                    className="rank_icon"
                  )
              td 
                span.value {{ data.box.blk }}
                span.rank(:class="getRankStyle(data.ranking_blk)") {{ getRankText(data.ranking_blk) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking_blk, data.ranking_blk)"
                  ) {{ Math.abs(data.last_ranking_blk - data.ranking_blk) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking_blk, data.ranking_blk)",
                    className="rank_icon"
                  )
              td 
                span.value {{ data.box.pts }}
                span.rank(:class="getRankStyle(data.ranking_pts)") {{ getRankText(data.ranking_pts) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking_pts, data.ranking_pts)"
                  ) {{ Math.abs(data.last_ranking_pts - data.ranking_pts) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking_pts, data.ranking_pts)",
                    className="rank_icon"
                  )
              td
                span.value {{ data.score }}
                span.rank(:class="getRankStyle(data.ranking)") {{ getRankText(data.ranking) }}
                  span.rank_num(
                    :class="getRankRise(data.last_ranking, data.ranking)"
                  ) {{ Math.abs(data.last_ranking - data.ranking) }}
                  svg-icon(
                    :iconClass="getRankRise(data.last_ranking, data.ranking)",
                    className="rank_icon"
                  )
    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
    v-row(v-if="$store.state.user.accessToken")
      v-col
        h3.text_f_blue 個人成績
    v-row.table_row.mt-0(v-if="$store.state.user.accessToken")
      v-col.table.f_table.self.table_col
        table
          thead
            tr.bottom_head
              th.text-left 名字
              th 助攻
              th 籃板
              th 抄截
              th 火鍋
              th 得分
              th 積分
          tbody
            tr(v-if="!personalData.user") 
              td.bdl(colspan="8") 尚無資料
            tr(
              v-if="personalData.user",
              style="cursor: pointer",
              :class="{ switch_rank: switchRankPersonal }",
              @click="switchRankPersonal = !switchRankPersonal"
            )
              td
                .player_container.d-flex.align-center
                  img.avatar(
                    v-if="personalData.user && personalData.user.photo && personalData.user.photo != ''",
                    :src="personalData.user.photo"
                  )
                  img.avatar(
                    v-if="!personalData.user || !personalData.user.photo || personalData.user.photo == ''",
                    :src="gcAvatar"
                  )
                  span.ml-1(v-if="personalData.user") {{ personalData.user.nickname }}
              td(v-if="personalData.box") 
                span.value {{ personalData.box.ast }}
                span.rank(:class="getRankStyle(personalData.ranking_ast)") {{ getRankText(personalData.ranking_ast) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking_ast, personalData.ranking_ast)"
                  ) {{ Math.abs(personalData.last_ranking_ast - personalData.ranking_ast) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking_ast, personalData.ranking_ast)",
                    className="rank_icon"
                  )
              td(v-if="personalData.box") 
                span.value {{ personalData.box.reb }}
                span.rank(:class="getRankStyle(personalData.ranking_reb)") {{ getRankText(personalData.ranking_reb) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking_reb, personalData.ranking_reb)"
                  ) {{ Math.abs(personalData.last_ranking_reb - personalData.ranking_reb) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking_reb, personalData.ranking_reb)",
                    className="rank_icon"
                  )
              td(v-if="personalData.box") 
                span.value {{ personalData.box.stl }}
                span.rank(:class="getRankStyle(personalData.ranking_stl)") {{ getRankText(personalData.ranking_stl) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking_stl, personalData.ranking_stl)"
                  ) {{ Math.abs(personalData.last_ranking_stl - personalData.ranking_stl) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking_stl, personalData.ranking_stl)",
                    className="rank_icon"
                  )
              td(v-if="personalData.box") 
                span.value {{ personalData.box.blk }}
                span.rank(:class="getRankStyle(personalData.ranking_blk)") {{ getRankText(personalData.ranking_blk) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking_blk, personalData.ranking_blk)"
                  ) {{ Math.abs(personalData.last_ranking_blk - personalData.ranking_blk) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking_blk, personalData.ranking_blk)",
                    className="rank_icon"
                  ) {{ personalData.box.blk }}
              td(v-if="personalData.box") 
                span.value {{ personalData.box.pts }}
                span.rank(:class="getRankStyle(personalData.ranking_pts)") {{ getRankText(personalData.ranking_pts) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking_pts, personalData.ranking_pts)"
                  ) {{ Math.abs(personalData.last_ranking_pts - personalData.ranking_pts) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking_pts, personalData.ranking_pts)",
                    className="rank_icon"
                  )
              td(v-if="personalData")
                span.value {{ personalData.score }}
                span.rank(:class="getRankStyle(personalData.ranking)") {{ getRankText(personalData.ranking) }}
                  span.rank_num(
                    :class="getRankRise(personalData.last_ranking, personalData.ranking)"
                  ) {{ Math.abs(personalData.last_ranking - personalData.ranking) }}
                  svg-icon(
                    :iconClass="getRankRise(personalData.last_ranking, personalData.ranking)",
                    className="rank_icon"
                  )

  dialog-search(
    :dialogSearch="dialogSearch",
    @closeSearchDialog="closeSearchDialog",
    @showOtherTeamDialog="showOtherTeamDialog"
  )
  dialog-other-team(
    :userMagic="userMagic",
    :dialogOtherTeam="dialogOtherTeam",
    @closeOtherTeamDialog="closeOtherTeamDialog"
  )
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p 讀取中
</template>

<script>
import gcAvatar from "@/assets/img/gc-avatar.png";
import DialogSearch from "@/components/dialog/DialogSearch";
import DialogOtherTeam from "@/components/dialog/DialogOtherTeam";
import DialogLoading from "@/components/dialog/DialogLoading";

import {
  getSeasonData,
  getOverviewData,
  getOverviewDataByUser,
} from "@/api/fantasy";
export default {
  name: "Overview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSearch,
    DialogOtherTeam,
    DialogLoading,
  },
  data() {
    return {
      dialogLoadingShow: true,
      seasonData: {},
      overviewData: {},

      mainSelect: "ALL",
      subSelect: {},
      subSelectList: [],

      allList: [],
      currentList: [],
      personalData: {},
      currentPage: 1,
      totalPages: 1,
      perPage: 10,

      sortValue: {
        type: "",
        order: "DESC",
      },

      userMagic: "",

      dialogSearch: false,
      dialogOtherTeam: false,
      gcAvatar,
      switchRankPlayer: -1,
      switchRankPersonal: false,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 排名 | Game Changer",
        description: "GC Fantasy 排名",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/overview",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    currentPage() {
      this.getCurrentData();
    },
    mainSelect() {
      this.overviewData = {};
      this.allList = [];
      this.currentList = [];
      this.personalData = {};
      this.currentPage = 1;
      this.totalPages = 1;

      switch (this.mainSelect) {
        case "ALL":
          this.subSelectList = [];
          this.subSelect = {};
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("ALL", null);
          } else {
            this.getOverviewDataByUser("ALL", null);
          }
          break;
        case "M":
          this.subSelectList = [];
          for (let index in this.seasonData.valid_months) {
            let temp = this.seasonData.valid_months[index];
            let item = {
              id: temp.id,
              key_first: temp.year,
              key_second: temp.month,
              name:
                temp.month < 10
                  ? temp.year + "0" + temp.month
                  : temp.year + "" + temp.month,
            };
            if (index == 0) {
              this.subSelect = item;
            }
            this.subSelectList.push(item);
          }
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("MONTH", this.subSelect.id);
          } else {
            this.getOverviewDataByUser("MONTH", this.subSelect.id);
          }
          break;
        case "W":
          this.subSelectList = [];
          for (let index in this.seasonData.valid_weeks) {
            let temp = this.seasonData.valid_weeks[index];
            let item = {
              id: temp.id,
              key_first: temp.start_date,
              key_second: temp.end_date,
              name: "~" + temp.end_date,
            };
            if (index == 0) {
              this.subSelect = item;
            }
            this.subSelectList.push(item);
          }
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("WEEK", this.subSelect.id);
          } else {
            this.getOverviewDataByUser("WEEK", this.subSelect.id);
          }
          break;
      }
    },
    subSelect() {
      this.overviewData = {};
      this.allList = [];
      this.currentList = [];
      this.personalData = {};
      this.currentPage = 1;
      this.totalPages = 1;
      switch (this.mainSelect) {
        case "M":
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("MONTH", this.subSelect.id);
          } else {
            this.getOverviewDataByUser("MONTH", this.subSelect.id);
          }
          break;
        case "W":
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("WEEK", this.subSelect.id);
          } else {
            this.getOverviewDataByUser("WEEK", this.subSelect.id);
          }
          break;
      }
    },
  },
  created() {
    this.getSeasonData();
  },
  methods: {
    async getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      this.dialogLoadingShow = true;
      await getSeasonData(temp).then((response) => {
        if (response.data.length > 0) {
          this.seasonData = response.data[0];
          if (!this.$store.state.user.accessToken) {
            this.getOverviewData("ALL", null);
          } else {
            this.getOverviewDataByUser("ALL", null);
          }
        }
      });
      this.dialogLoadingShow = false;
    },
    async getOverviewData(type, id) {
      this.dialogLoadingShow = true;
      let temp;
      if (id == null) {
        temp = {
          season_id: this.seasonData.id,
          type: type,
        };
      } else {
        temp = {
          season_id: this.seasonData.id,
          type: type,
          type_id: id,
        };
      }
      await getOverviewData(temp).then((response) => {
        this.overviewData = response.data;

        this.sortValue.type = "score";
        this.allList = this.overviewData.score_list;
        this.getCurrentData();
      });
      this.dialogLoadingShow = false;
    },
    async getOverviewDataByUser(type, id) {
      this.dialogLoadingShow = true;
      let temp;
      if (id == null) {
        temp = {
          season_id: this.seasonData.id,
          type: type,
        };
      } else {
        temp = {
          season_id: this.seasonData.id,
          type: type,
          type_id: id,
        };
      }
      await getOverviewDataByUser(temp).then((response) => {
        this.overviewData = response.data;

        this.personalData = this.overviewData.my_team;

        this.sortValue.type = "score";
        this.allList = this.overviewData.score_list;
        this.getCurrentData();
      });
      this.dialogLoadingShow = false;
    },
    getCurrentData() {
      this.totalPages = Math.ceil(this.allList.length / this.perPage);
      this.currentList = [];
      let number = this.perPage * this.currentPage;
      this.currentList = this.allList.slice(number - this.perPage, number);
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "caret-down"];
      }
    },
    getRankTitle() {
      switch (this.sortValue.type) {
        case "pts":
          return "得分";
        case "blk":
          return "火鍋";
        case "stl":
          return "抄截";
        case "reb":
          return "籃板";
        case "ast":
          return "助攻";
        case "score":
          return "積分";
        default:
          return "";
      }
    },
    sortClick(type) {
      if (this.sortValue.type == type) {
        return;
      }
      this.sortValue.type = type;
      switch (type) {
        case "pts":
          this.allList = this.overviewData.pts_list;
          break;
        case "blk":
          this.allList = this.overviewData.blk_list;
          break;
        case "stl":
          this.allList = this.overviewData.stl_list;
          break;
        case "reb":
          this.allList = this.overviewData.reb_list;
          break;
        case "ast":
          this.allList = this.overviewData.ast_list;
          break;
        case "score":
          this.allList = this.overviewData.score_list;
          break;
      }
      this.currentPage = 1;
      this.getCurrentData();
    },
    getRankStyle(rank) {
      if (rank == 1) {
        return "gold";
      } else if (rank == 2) {
        return "sliver";
      } else if (rank == 3) {
        return "copper";
      } else {
        return "";
      }
    },
    getRankText(rank) {
      switch (rank) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        default:
          return rank + "th";
      }
    },
    getRankRise(last, current) {
      if (last == 0) {
        return "rank_flat";
      } else if (last - current < 0) {
        return "rank_down";
      } else if (last - current > 0) {
        return "rank_up";
      } else {
        return "rank_flat";
      }
    },
    getSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        return "switch_rank";
      } else {
        return "";
      }
    },
    setSwitchRankPlayer(index) {
      if (this.switchRankPlayer == index) {
        this.switchRankPlayer = -1;
      } else {
        this.switchRankPlayer = index;
      }
    },
    showSearchDialog() {
      this.dialogSearch = true;
    },
    closeSearchDialog() {
      this.dialogSearch = false;
    },
    showOtherTeamDialog(data) {
      this.userMagic = data.user.magic_token;
      this.dialogOtherTeam = true;
    },
    closeOtherTeamDialog() {
      this.dialogOtherTeam = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/fantasy/overview";
</style>
